import React from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { useDispatch } from "react-redux";
import { ChangeOrderStatusAction } from "../../redux/actions/OrdersAction";
import { Tag } from "primereact/tag";

export default function OrdersTable({
  latestOrders,
  completedOrders,
  locationId,
  formattedTime,
  getColor,

  getStyle,
}) {
  const dispatch = useDispatch();

  const handleCheck = (id, status) => {
    dispatch(ChangeOrderStatusAction(id, locationId, status));
  };

  const switchTemplate = (rowData) => {
    return (
      <div className="flex justify-content-center">
        <>
          <i
            className="pi text-green-500 pi-check-circle mx-2 cursor-pointer text-3xl"
            onClick={() => handleCheck(rowData?._id, "READY")}
          ></i>
        </>
      </div>
    );
  };

  const addOns = (rowData) => {
    const items = rowData?.selectedAddOns.map((item, i) => (
      <span className="text-green-500">
        {i ? "," : ""} {item?.name}
      </span>
    ));
    return items;
  };
  const Ingredients = (rowData) => {
    const items = rowData?.removedIngredients.map((item, i) => (
      <span className="text-red-500">
        {i ? "," : ""} {item?.name}
      </span>
    ));
    return items;
  };

  const time = (rowData) => {
    return <>{rowData?.pickupTime}</>;
  };

  const statusBodyTemplate = (data) => {
    return <Tag value={data.status} severity={getSeverity(data.status)}></Tag>;
  };

  const getSeverity = (status) => {
    switch (status) {
      case "READY":
        return "success";
      case "CANCELED":
        return "danger";
      default:
        return "success";
    }
  };

  const getPrice = (price) => {
    return parseFloat(price).toFixed(2);
  };

  const paymentStatus = (item) => {
    let paymentStatus = "";

    if (item.invoiceId) {
      paymentStatus = "Paid";
      if (item.onlinePaymentStatus === "PAID") {
        paymentStatus = "Paid:Mobile";
      }
    }
    return paymentStatus;
  };

  return (
    <>
      {formattedTime}
      <TableCard title="Latest Orders">
        <div className="grid surface-ground font-bold p-2 largescreen-text">
          <div className="col-1">Zeit</div>
          <div className="col-8 m-0">
            <div className="grid">
              <div className="col-1">X</div>
              <div className="col-3">Produkt</div>
              <div className="col-3">Estado de pago</div>
              <div className="col-3">Ohne/Extra</div>
            </div>
          </div>
          <div className="col-1">Preise</div>
          <div className="col-2 text-center">Status</div>
        </div>

        {latestOrders.map((order) => (
          <div
            className={`grid p-2 border-1 mb-3  ${getColor(
              order
            )}  shadow-1 largescreen-text`}
            style={getStyle(order)}
          >
            <div className="col-1 font-bold">{time(order)}</div>
            <div className="col-8 m-0">
              {order?.cart?.cartItems.map((item, i) => (
                <div className="grid">
                  <div className="col-1 font-semibold">{item?.qty}</div>
                  <div className="col-3 font-medium">{item?.product?.name}</div>
                  <div className="col-3 font-medium">
                    {paymentStatus(order)}
                  </div>
                  <div className="col-3 font-medium">
                    {Ingredients(item)}
                    {addOns(item)}
                  </div>
                </div>
              ))}
            </div>
            <div className="col-1 font-medium">
              € {getPrice(order?.cart?.cartGrandTotal)}
            </div>
            <div className="col-2">{switchTemplate(order)}</div>
          </div>
        ))}
      </TableCard>
      <br />
      <TableCard title="Completed Orders">
        <div className="grid surface-ground font-bold p-2 largescreen-text">
          <div className="col-1">Zeit</div>
          <div className="col-8 m-0">
            <div className="grid">
              <div className="col-1">X</div>
              <div className="col-3">Produkt</div>
              <div className="col-3">Estado de pago</div>
              <div className="col-3">Ohne/Extra</div>
            </div>
          </div>
          <div className="col-1">Preise</div>
          <div className="col-2 text-center">Status</div>
        </div>

        {completedOrders.map((order) => (
          <div
            className={`grid p-2 border-1 mb-3  ${getColor(
              order
            )}  shadow-1 largescreen-text`}
            style={getStyle(order)}
          >
            <div className="col-1 font-bold">{time(order)}</div>
            <div className="col-8 m-0">
              {order?.cart?.cartItems.map((item, i) => (
                <div className="grid">
                  <div className="col-1 font-semibold">{item?.qty}</div>
                  <div className="col-3 font-medium">{item?.product?.name}</div>
                  <div className="col-3 font-medium">
                    {paymentStatus(order)}
                  </div>
                  <div className="col-3 font-medium">
                    {Ingredients(item)}
                    {addOns(item)}
                  </div>
                </div>
              ))}
            </div>
            <div className="col-1 font-medium">
              € {getPrice(order?.cart?.cartGrandTotal)}
            </div>
            <div className="col-2 text-center">{statusBodyTemplate(order)}</div>
          </div>
        ))}
      </TableCard>
    </>
  );
}
